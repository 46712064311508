import React, { Fragment, useState, useEffect } from 'react';
import { Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import OKR_LOGO from '../../../images/Unlock_U_Logo.png';

import { getMonthDateYearTime, getMonthDateYear, getUserName } from '../../../config/utils';

import { styles } from './PipPdfStyle';
import Html from 'react-pdf-html';

function parseCKEditorTableHtml(html) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');

	// Replace <figure> with <div> and transfer content
	Array.from(doc.querySelectorAll('figure')).forEach((figure) => {
		const div = document.createElement('div');
		div.innerHTML = figure.innerHTML;
		figure.replaceWith(div);
	});

	// Remove styles from <table>, <tr>, and <td> elements
	['table', 'tr', 'td'].forEach((tag) => {
		Array.from(doc.querySelectorAll(tag)).forEach((el) => {
			el.removeAttribute('style'); // Remove inline styles
		});
	});

	// Remove unwanted tags (<p>, <span>, <strong>, <div>) inside <td>, <th>, and <table>
	const unwantedTags = ['p', 'span', 'strong', 'div'];
	['td', 'th', 'table'].forEach((parentTag) => {
		Array.from(doc.querySelectorAll(parentTag)).forEach((parent) => {
			unwantedTags.forEach((tag) => {
				Array.from(parent.querySelectorAll(tag)).forEach((el) => {
					el.replaceWith(...el.childNodes); // Replace tag with its content
				});
			});
		});
	});

	// Extract and preserve column widths in <th> elements
	Array.from(doc.querySelectorAll('table')).forEach((table) => {
		const headerCells = Array.from(table.querySelectorAll('tr th'));
		headerCells.forEach((cell) => {
			if (cell.style.width) {
				cell.style.width = cell.style.width; // Retain existing width
			}
		});
	});

	// Return modified HTML content
	return doc.body.innerHTML;
}

const getSource = (imagePath, type) =>
	type === 1
		? {
				uri: imagePath,
				method: 'GET',
		  }
		: imagePath;
// const html = `
// <p>vdsdsdsffsdf</p><p>&nbsp;</p><p><strong>sasas</strong></p><p><i>asasasa</i></p><p><a target="_blank" rel="noopener noreferrer" href="http://SDSADSD">http://SDSADSD</a></p><ul style="list-style-type:circle;"><li>FDF</li><li>JKJHK</li></ul><p>&nbsp;</p><ol style="list-style-type:lower-latin;"><li>DFSD</li><li>LK;</li><li>&nbsp;</li></ol>
// 		`;
export function PipDetailPdf(props) {
	const { data } = props;
	const { t } = useTranslation();

	return (
		<Fragment>
			<Document>
				<Page size='A4' style={styles.pageFirst}>
					<View style={styles.pageFirst_wrap}>
						<View fixed style={styles.header_top}>
							<View style={styles.pageHeaderSection}>
								{Boolean(data?.companyLogo) && data?.companyLogo.trim() !== '' ? (
									<Image style={[styles.customerLogo]} source={getSource(data?.companyLogo, 1)} />
								) : (
									<></>
								)}
								<Image style={styles.unlockULogo} source={getSource(OKR_LOGO, 0)} />
							</View>
							<View style={styles.pipMainHeader}>
								<Text style={[styles.pipMainHeaderText, styles.fontMedium]}>Performance Improvement Plan</Text>
								<View style={styles.pipDuration}>
									<Text style={[styles.pipDurationHead, styles.fontMedium]}>{'Duration: '}</Text>
									{data?.timeframeStartDate && data?.timeframeEndDate && data?.isTimeFrameAlert ? (
										<Text style={[styles.pipDurationDate, styles.timeframeAlertText]}>
											{getMonthDateYear(data?.timeframeStartDate)} - {getMonthDateYear(data?.timeframeEndDate)}(
											{'Timeframe Passed'})
										</Text>
									) : (
										<Text style={styles.pipDurationDate}>
											{getMonthDateYear(data?.timeframeStartDate)} - {getMonthDateYear(data?.timeframeEndDate)}
										</Text>
									)}
								</View>
							</View>
							<View style={styles.pipSectionTitle}>
								<Text style={[styles.pipSectionTitleText, styles.fontMedium]}>General Details</Text>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Employee Name'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{`${data?.nomineeDetails?.firstName} ${data?.nomineeDetails?.lastName}`}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Reporting Manager'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data?.reporingManagerName ? data?.reporingManagerName : '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Title'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>{data?.title ? data?.title : '--'}</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'PIP Status'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data?.performanceImprovementPlanStatus ? data?.performanceImprovementPlanStatus : '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Created On'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data?.createdOn ? getMonthDateYearTime(data?.createdOn) : '--'}
									</Text>
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Created By'}</Text>
									<Text style={[styles.employeeColText, styles.fontMedium]}>
										{data?.initiatorName ? data?.initiatorName : '--'}
									</Text>
								</View>
							</View>
							<View style={styles.employeeRow}>
								{/* <View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'PIP Duration'}</Text>
									{data?.timeframeStartDate && data?.timeframeEndDate && data?.isTimeFrameAlert ? (
										<Text style={[styles.employeeColText, styles.timeframeAlertText, styles.fontMedium]}>
											{getMonthDateYear(data?.timeframeStartDate)} - {getMonthDateYear(data?.timeframeEndDate)}(
											{'Timeframe Passed'})
										</Text>
									) : (
										<Text style={[styles.employeeColText, styles.fontMedium]}>
											{getMonthDateYear(data?.timeframeStartDate)} - {getMonthDateYear(data?.timeframeEndDate)}
										</Text>
									)}
								</View> */}
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Reviewers'}</Text>
									{data?.distinctPerformanceImprovementPlanReviewers?.map((reviewDates, index) => {
										return (
											<Text style={[styles.employeeColText, styles.fontMedium]} key={index}>
												{reviewDates?.firstName} {reviewDates?.lastName}
												{/* {index < data?.distinctPerformanceImprovementPlanReviewers?.length - 1 ? ', ' : ''} */}
											</Text>
										);
									})}
								</View>
								<View style={styles.employeeCol}>
									<Text style={styles.employeeColTitle}>{'Feedbacks Linked'}</Text>
									{data?.linkedFeedbacks?.length ? (
										data?.linkedFeedbacks?.map((feedback, index) => {
											return (
												<React.Fragment key={index}>
													<Text style={[styles.employeeColText, styles.fontMedium]}>{feedback?.title}</Text>
												</React.Fragment>
											);
										})
									) : (
										<Text style={[styles.employeeColText, styles.fontMedium]}>Not linked</Text>
									)}
								</View>
							</View>
							<View style={styles.employeeRow}>
								<View style={styles.employeeColFull}>
									<Text style={styles.employeeColTitle}>{'Documents Uploaded'}</Text>
									{data?.documents?.length ? (
										data?.documents?.map((file, index) => {
											return (
												<React.Fragment key={index}>
													<Text style={[styles.employeeColText, styles.fontMedium]}>{file?.uploadFileName}</Text>
												</React.Fragment>
											);
										})
									) : (
										<Text style={[styles.employeeColText, styles.fontMedium]}>Not uploaded</Text>
									)}
								</View>
							</View>
						</View>
					</View>
				</Page>
				<Page size='A4' style={styles.page}>
					<View style={styles.page_wrap}>
						<View style={styles.pipSection}>
							<View style={styles.pipSectionTitle}>
								<Text style={[styles.pipSectionTitleText, styles.fontMedium]}>Objectives & Expectations</Text>
							</View>
							<View style={styles.pipBlock}>
								<View style={styles.pipBlockHead}>
									<Text style={styles.pipBlockHeadTitle}>Areas Of Concern</Text>
								</View>
								{data?.areasOfConcern ? (
									<View style={styles.editorBox}>
										<Html stylesheet={[styles]}>{parseCKEditorTableHtml(data?.areasOfConcern || '')}</Html>
									</View>
								) : (
									<View style={styles.editorBox}>
										<View style={styles.noContentBlock}>
											<Text style={styles.noContentText}>Not data found</Text>
										</View>
									</View>
								)}
							</View>
							<View style={styles.pipBlock}>
								<View style={styles.pipBlockHead}>
									<Text style={styles.pipBlockHeadTitle}>Role Expectations</Text>
								</View>
								{data?.roleExpectations ? (
									<View style={styles.editorBox}>
										<Html stylesheet={[styles]}>{parseCKEditorTableHtml(data?.roleExpectations || '')}</Html>
									</View>
								) : (
									<View style={styles.editorBox}>
										<View style={styles.noContentBlock}>
											<Text style={styles.noContentText}>Not data found</Text>
										</View>
									</View>
								)}
							</View>
							<View style={styles.pipBlock}>
								<View style={styles.pipBlockHead}>
									<Text style={styles.pipBlockHeadTitle}>Improvement Goals</Text>
								</View>
								{data?.improvementGoals ? (
									<View style={styles.editorBox}>
										<Html stylesheet={[styles]}>{parseCKEditorTableHtml(data?.improvementGoals)}</Html>
									</View>
								) : (
									<View style={styles.editorBox}>
										<View style={styles.noContentBlock}>
											<Text style={styles.noContentText}>Not data found</Text>
										</View>
									</View>
								)}
							</View>
						</View>
					</View>
				</Page>
				<Page size='A4' style={styles.page}>
					<View style={styles.page_wrap}>
						<View style={styles.pipSection}>
							<View style={styles.pipBlock}>
								<View style={styles.pipSectionTitle}>
									<Text style={[styles.pipSectionTitleText, styles.fontMedium]}>Tasks</Text>
								</View>
								{data?.taskPdfListings?.length ? (
									<View style={styles.taskBlock}>
										{data?.taskPdfListings?.map((task, index) => {
											return (
												<View style={styles.taskPanel}>
													<Text style={styles.taskTitle}>{task?.taskName}</Text>
													<View style={styles.taskDetails}>
														<View style={styles.taskDetail}>
															<Text style={styles.greyTitle}>Priority: </Text>
															<Text style={styles.blackSmallText}>{task?.priority}</Text>
														</View>
														<View style={styles.taskDetail}>
															<Text style={styles.greyTitle}>Delegated to: </Text>
															<Text style={styles.blackSmallText}>{task?.assignedToName}</Text>
														</View>
														{task?.dueDate ? (
															<View style={styles.taskDetail}>
																<Text style={styles.greyTitle}>Due On: </Text>
																<Text style={styles.blackSmallText}>{getMonthDateYear(task?.dueDate)}</Text>
															</View>
														) : (
															<></>
														)}
														<View style={styles.taskDetail}>
															<Text style={styles.greyTitle}>Added to check-in: </Text>
															<Text style={styles.blackSmallText}>{task?.isCheckedInLinked ? 'Yes' : 'No'}</Text>
														</View>
													</View>
													{task?.taskLink ? (
														<View style={styles.taskLinkDetail}>
															<Text style={styles.greyTitle}>Link details: </Text>
															<Text style={styles.blackSmallText}>{task?.taskLink}</Text>
														</View>
													) : (
														<></>
													)}
												</View>
											);
										})}
									</View>
								) : (
									<View style={styles.noContentBlock}>
										<Text style={styles.noContentText}>No tasks created</Text>
									</View>
								)}
							</View>
						</View>
					</View>
				</Page>
				<Page size='A4' style={styles.page}>
					<View style={styles.page_wrap}>
						<View style={styles.pipSection}>
							<View style={styles.pipBlock}>
								<View style={styles.pipSectionTitle}>
									<Text style={[styles.pipSectionTitleText, styles.fontMedium]}>Review Details</Text>
								</View>
								{data?.performanceImprovementPlanReviewDates?.map((reviewDetail, index) => {
									return (
										<View style={styles.reviewContentBlock}>
											<View style={styles.reviewContentHead}>
												{Boolean(data?.initiatorImagePath) && data?.initiatorImagePath.trim() !== '' ? (
													<Image
														style={[styles.user_image, styles.user_image_initiator]}
														source={getSource(data?.initiatorImagePath, 1)}
													/>
												) : (
													<View style={[styles.user_initial, styles.user_image_initiator]}>
														<Text style={styles.user_initial_name}>
															{getUserName({
																fullName: data?.initiatorName,
																firstName: '',
																lastName: '',
															})}
														</Text>
													</View>
												)}
												<View>
													<Text style={styles.reviewInitiator}>{data?.initiatorName}</Text>
													<Text style={[styles.reviewInitiatedDate, styles.fontMedium]} key={index}>
														{getMonthDateYear(reviewDetail?.reviewDate)}
													</Text>
												</View>
											</View>
											<View style={styles.reviewContentDetails}>
												{reviewDetail?.performanceImprovementPlanReviewers.length ? (
													<View style={styles.reviewerList}>
														<Text style={styles.reviewerTitle}>Reviewers</Text>
														<View style={styles.reviewersDetailsSetion} breakInside='avoid'>
															{reviewDetail?.performanceImprovementPlanReviewers?.map((reviewer, index) => {
																return (
																	<Text style={[styles.reviewerName, styles.fontNormal]}>
																		{reviewer?.firstName} {reviewer?.lastName}
																	</Text>
																);
															})}
														</View>
													</View>
												) : (
													<></>
												)}
												{reviewDetail?.commentListing?.length ? (
													<View style={styles.commentsList}>
														<Text style={styles.commentTitle}>Comments</Text>
														{reviewDetail?.commentListing?.map((commentInfo, index) => {
															return (
																<View style={styles.commentsUserInfo}>
																	{/* {Boolean(commentInfo?.imagePath) && commentInfo?.imagePath.trim() !== '' ? (
																		<Image style={styles.user_image} source={getSource(commentInfo?.imagePath, 1)} />
																	) : (
																		<View style={styles.user_initial}>
																			<Text style={styles.user_initial_name}>
																				{getUserName({
																					fullName: commentInfo?.fullName,
																					firstName: '',
																					lastName: '',
																				})}
																			</Text>
																		</View>
																	)} */}
																	<View style={styles.commentsUserInfoInner}>
																		<View style={styles.commentsUserInfoDetails}>
																			<Text style={styles.blackSmallText}>
																				{commentInfo?.firstName} {commentInfo?.lastName}
																			</Text>
																			<Text style={styles.greyTitle}>{getMonthDateYear(commentInfo?.createdOn)}</Text>
																		</View>
																		<View style={styles.commentsUserText}>
																			<Html stylesheet={styles}>{parseCKEditorTableHtml(commentInfo?.comments)}</Html>
																		</View>
																	</View>
																</View>
															);
														})}
													</View>
												) : (
													<View style={styles.commentsList}>
														<Text style={styles.commentTitle}>Comments:</Text>
														<View style={styles.noContentBlock}>
															<Text style={styles.noContentText}>No comments yet</Text>
														</View>
													</View>
												)}
											</View>
										</View>
									);
								})}
							</View>
						</View>
					</View>
				</Page>
				<Page size='A4' style={styles.page}>
					<View style={styles.page_wrap}>
						<View style={styles.pipSection}>
							<View style={styles.pipBlock}>
								<View style={styles.pipSectionTitle}>
									<Text style={[styles.pipSectionTitleText, styles.fontMedium]}>Resolution Details</Text>
								</View>
								{data?.resolveDetails?.firstName ? (
									<View style={styles.resolvedBlock}>
										<View style={styles.resolvedPanel}>
											<View style={styles.employeeRow}>
												<View style={styles.employeeColThird}>
													<Text style={styles.employeeColTitle}>{'Resolve Status'}</Text>
													<Text style={[styles.employeeColText, styles.fontMedium]}>
														{data?.performanceImprovementPlanStatus}
													</Text>
												</View>
												<View style={styles.employeeColThird}>
													<Text style={styles.employeeColTitle}>{'Resolve By'}</Text>
													<Text style={[styles.employeeColText, styles.fontMedium]}>
														{data?.resolveDetails?.firstName} {data?.resolveDetails?.lastName}
													</Text>
												</View>
												<View style={styles.employeeColThird}>
													<Text style={styles.employeeColTitle}>{'Resolve On'}</Text>
													<Text style={[styles.employeeColText, styles.fontMedium]}>
														{data?.resolvedOn ? getMonthDateYear(data?.resolvedOn) : ''}
													</Text>
												</View>
											</View>
											<View style={styles.employeeRow}>
												<View style={styles.employeeColFull}>
													<Text style={styles.employeeColTitle}>{'Resolution Comment'}</Text>
													<Html stylesheet={styles}>{parseCKEditorTableHtml(data?.resolveDetails?.statusComment)}</Html>
												</View>
											</View>
										</View>
									</View>
								) : (
									<View style={styles.noContentBlock}>
										<Text style={styles.noContentText}>Not yet Resolved</Text>
									</View>
								)}
							</View>
						</View>
					</View>
					{/* Footer */}
					{/* <View style={styles.footer}>
						<View style={styles.footerInner}>
							<View style={styles.footerLogo}>
								<Image style={styles.unlockUfooterLogo} source={getSource(OKR_LOGO, 0)} />
							</View>
							<View style={styles.footerText}>
								<Text style={styles.infoProLearn_text}>&copy; 2024 Infopro Learning</Text>
							</View>
						</View>
					</View> */}
				</Page>
			</Document>
		</Fragment>
	);
}
