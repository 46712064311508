import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { CategoryColoredIcon } from '../../../config/svg/MyGoalSvg';
import { CategoryAttachments } from './CategoryAttachements';
import { CategoryConversations } from './CategoryConversations';
import { useSelector } from 'react-redux';
import LinearLoader from '../../Common/Loader';

export const CategorySettingsTabPanel: React.FC<any> = (props: any) => {
	const { performanceGoalsList, children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`category-settings-tab-${index}`}
			aria-labelledby={`category-settings-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

CategorySettingsTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `category-settings-tabpanel-${index}`,
		'aria-controls': `category-settings-tabpanel-${index}`,
	};
}

export const CategorySettingsDrawer: React.FC<any> = (props) => {
	const { t, handleTabChange = {}, tabSelected = 0, loader, setLoader, isTaskEdited, setIsTaskEdited } = props;
	const [goalDocumentCount, setGoalDocumentCount] = useState<number>(0);
	const { goalReviewAttachmentCont } = useSelector((state: any) => state?.adminSettingReducer);
	useEffect(() => {
		//setGoalDocumentCount(props.kr.documentCount);
	}, []);
	return (
		<>
			<Box className='added-category-name sssaaa'>
				<Typography variant='h4'>
					<CategoryColoredIcon />
					{props.kr.categoryName}
				</Typography>
			</Box>
			<Tabs className='okr-tabs drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab
					className='arrow-tab'
					label={`${t('attachmentsLabel')} ${goalReviewAttachmentCont ? '(' + goalReviewAttachmentCont + ')' : ''}`}
					value={0}
					{...a11yProps(0)}
				/>
				<Tab className='arrow-tab' label={t('conversationLabel')} value={1} {...a11yProps(1)} />
			</Tabs>
			{loader && <LinearLoader />}
			<Box className={`drawer-tabs-panel`}>
				<CategorySettingsTabPanel value={tabSelected} index={0}>
					<CategoryAttachments
						{...props}
						goalDocumentCount={goalDocumentCount}
						setGoalDocumentCount={setGoalDocumentCount}
						loader={loader}
						setLoader={setLoader}
					/>
				</CategorySettingsTabPanel>
				<CategorySettingsTabPanel value={tabSelected} index={1}>
					<CategoryConversations
						{...props}
						tabSelected={tabSelected}
						isTaskEdited={isTaskEdited}
						setIsTaskEdited={setIsTaskEdited}
					/>
				</CategorySettingsTabPanel>
			</Box>
		</>
	);
};
