import React, { Fragment, useEffect, useState } from 'react';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { CategorySettingsDrawer } from './CategorySettingsDrawer';
import { getMonthDateYearTime } from '../../../config/utils';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import { useDispatch } from 'react-redux';
import { fetchConversationGoalCompleted } from '../../../action/perform';
import AlertDialog from '../../Common/Dialog';
import { setPerfomanceGoalReviewAttachmentCount } from '../../../action/adminSettings';

export const CategorySettings: React.FC<any> = (props) => {
	const { t, categorySettingsOpen, handleSettingsDrawerClose, selectTab, performanceGoalsList } = props;
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [loader, setLoader] = useState<boolean>(false);

	const handleTabChange = (event: any, newValue: Number) => {
		if (!showPopup(2, newValue)) {
			setTabSelected(newValue);
		}
	};
	const dispatch = useDispatch();
	const [isTaskEdited, setIsTaskEdited] = React.useState<boolean>(false);

	const [modalOpenProps, setModalOpenProps] = useState<any>({ open: false, type: 0 });
	useEffect(() => {
		dispatch(setPerfomanceGoalReviewAttachmentCount(0));
	}, []);
	useEffect(() => {
		if (selectTab === 'attachments') {
			setTabSelected(0);
		} else if (selectTab === 'conversation') {
			dispatch(
				fetchConversationGoalCompleted({
					entity: {
						commentDetailResponses: [],
					},
					pagingInfo: {},
				})
			);
			setTabSelected(1);
		}
	}, [selectTab]);
	const showPopup = (type: any, value: any) => {
		if (isTaskEdited === true) {
			setModalOpenProps({ open: true, type: type, value: value });
		}
		return isTaskEdited;
	};
	const onSettingsDrawerClose = (event: any) => {
		if (!showPopup(1, '')) {
			handleSettingsDrawerClose(event);
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setIsTaskEdited(false);
			if (modalOpenProps.type === 2) {
				setTabSelected(modalOpenProps.value);
			} else {
				handleSettingsDrawerClose(e);
			}
		}
		setModalOpenProps({ open: false, type: 0 });
	};
	return (
		<Fragment>
			<OKRDrawer
				open={categorySettingsOpen.open}
				drawerClassName='main-drawer-panel main-drawer-no-footer category-settings-drawer'
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={
					<>
						{t('categoryLevel')}
						{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
							<Tooltip title={t('goalSettingDuration')} arrow>
								<span>
									<CalendarIcon />
									{`${getMonthDateYearTime(performanceGoalsList?.settingDurationStartDate)} - ${getMonthDateYearTime(
										performanceGoalsList?.settingDurationEndDate
									)}`}
								</span>
							</Tooltip>
						)}
					</>
				}
				children={
					<Box className='drawer-inner-content'>
						<CategorySettingsDrawer
							kr={{
								...categorySettingsOpen.data,
								goalSourceId: categorySettingsOpen.data.performanceGoalLinkedFormCategoryId,
							}}
							t={t}
							handleTabChange={handleTabChange}
							tabSelected={tabSelected}
							{...props}
							loader={loader}
							setLoader={setLoader}
							isTaskEdited={isTaskEdited}
							setIsTaskEdited={setIsTaskEdited}
						/>
					</Box>
				}
				moreButton={<></>}
				handleDrawerClose={(event) => onSettingsDrawerClose(event)}
				handleSaveClick={(event) => handleSettingsDrawerClose(event)}
				handleCancelClick={(event) => onSettingsDrawerClose(event)}
				hideDefaultButton={false}
				loader={loader}
				isFooterVisible={false}
				isSaveButtonDisabled={false}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{modalOpenProps.open && (
				<AlertDialog
					module='organization'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpenProps.open}
				/>
			)}
		</Fragment>
	);
};
