import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CfrNoRecord } from '../CfrNoRecord';
import ConversationImg from '../../../images/no-found-conversation.svg';
import {
	Avatar,
	Box,
	Chip,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { CommentIcon, KebabIcon, LikeFilledIcon, LikeIcon } from '../../../config/svg/CommonSvg';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { DeleteIcon, EditIcon, InformationIcon, ReportsTooltipIcon } from '../../../config/svg/CommonSvgIcon';
import { ReadMore } from '../ReadMore';
import { AddText } from '../AddText';
import { useSnackbar } from 'notistack';
import { EnumCommentModuleId, EnumLikeModule, Enums } from '../../../config/enums';
import {
	getCurrentUserDetails,
	getRandomBackgroundColor,
	getRandomColor,
	getTime,
	getUserDetails,
	getUserName,
	GAevent,
	isTrialVersion,
} from '../../../config/utils';
import { EditConversations } from './EditConversations';
import AlertDialog from '../../Common/Dialog';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { CreateComment } from '../../Recognize/RecognizeListing/CreateComment';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import LikeImg from '../../../images/Like.svg';
import { ReplyList } from '../ReplyListing/ReplyList';
import { ReplyArrow } from '../../../config/svg/ArrowSvg';
import { useDispatch, useSelector } from 'react-redux';
import { getGoalDataApi, saveCommentGoalData, saveLikeGoalData } from '../../../action/perform';
import { deleteCommentsPost } from '../../../action/recognize';
import { getPerformanceGoalDocument, setPerfomanceGoalReviewAttachmentCount } from '../../../action/adminSettings';

export const ConversationsGoal: React.FC<any> = (props) => {
	const {
		tabSelected,
		okr,
		type,
		kr,
		conversationCount,
		setConversationCount,
		setLoader,
		// setIsCfrUpdated,
		setIsTaskEdited,
		isTaskEdited,
		isPastCycle,
		setUnsavedCfrProps,
		setHideBox,
		hideBox,
		userTagged,
		setUserTaggged,
		performanceGoalsList,
		isTeamReviewConversation = false,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [showEditor, setShowEditor] = useState(false);
	const [savedId, setSavedId] = useState(0);
	const [conversationContent, setConversationContent] = React.useState<string>('');
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [clickedData, setClickedData] = useState<any>({});
	const [editedConversation, setEditedConversation] = useState<any>({});
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
	const [conversationAction, setconversationAction] = useState<any>('');
	const [imageList, setImageList] = useState<any[]>([]);
	const currentUser = getCurrentUserDetails();
	const loggedInUserDetail = getUserDetails();
	const [pageIndex, setPageIndex] = useState(1);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [listData, setListData] = useState<any[]>([]);
	const [disableBtn, setDisableBtn] = useState<boolean>(false);
	const [listPending, setListPending] = useState<boolean>(true);
	const [conversationReplyId, setConversationReplyId] = useState<number>(0);
	const [showReplyBox, setShowReplyBox] = useState<boolean>(false);
	let userDetail = getUserDetails();
	const [replyHighlight, setReplyHighlight] = useState(false);
	const [replyUser, setReplyUser] = useState<any>(null);
	const [likeAnimation, setLikeAnimation] = useState<number>(0);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {} });
	const { performanceGoalCycle } = useSelector((state: any) => state?.adminSettingReducer);
	const { getConversationStatus, conversationList } = useSelector((state: any) => state?.performReducer);
	const [highlightIds, setHighlightIds] = useState<any[]>([]);
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);

	const dispatch = useDispatch();

	React.useEffect(() => {
		setListPending(true);
		setListData([]);
		getConversation();
		if (!isTeamReviewConversation) {
			getUserPerformanceGoalsList();
		}
	}, []);

	React.useEffect(() => {
		if (getConversationStatus === 'success') {
			setConversationCount(conversationList.pagingInfo ? conversationList.pagingInfo.totalRecords : 0);
			setPageIndex(conversationList.pagingInfo ? conversationList.pagingInfo.pageIndex : 1);
			if (
				conversationList.pagingInfo?.totalPages > 1 &&
				conversationList.pagingInfo?.totalPages > conversationList.pagingInfo?.pageIndex
			) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}

			if (conversationList.pagingInfo?.pageIndex === 1) {
				let data = conversationList.entityList;
				setListData(conversationList.entityList);
				let highlightIdList: any[] = [];
				data.forEach((item: any) => {
					if (item.isCommentHighlight) {
						highlightIdList.push(item.commentDetailsId);
					}
				});
				setHighlightIds(highlightIdList);
				let conversationHighlight: any = getLocalStorageItem('conversationHighlight');
				if (Boolean(conversationHighlight)) {
					conversationHighlight = JSON.parse(conversationHighlight);
					if (conversationHighlight.conversationsReplyId > 0) {
						setReplyHighlight(true);
						setConversationReplyId(conversationHighlight.conversationId);
						scrollTo(`${'recogConversationPost-' + conversationHighlight.conversationId}`);
					} else if (conversationHighlight.conversationId > 0) {
						setSavedId(conversationHighlight.conversationId);
						scrollTo(`${'recogConversationPost-' + conversationHighlight.conversationId}`);
						setTimeout(() => {
							setSavedId(0);
							removeLocalStorageItem('conversationHighlight');
						}, 7000);
					}
				}
				setTimeout(() => {
					removeLocalStorageItem('isNoteRedirection');
				}, 7000);
				setTimeout(() => {
					setHighlightIds([]);
				}, 3000);
			} else {
				let data: any[] = [...conversationList.entityList];
				let highlightIdList: any[] = [];
				data.forEach((item: any) => {
					if (item.isCommentHighlight) {
						highlightIdList.push(item.commentDetailsId);
					}
				});
				setHighlightIds(highlightIdList);
				if (conversationList.entityList.length > 0) {
					setListData([...listData, ...conversationList.entityList]);
				} else {
					setListData([]);
				}
				setTimeout(() => {
					setHighlightIds([]);
				}, 3000);
			}
			listPending && setListPending(false);
			setLoader(false);
			setIsTaskEdited(false);
		} else {
			// if (getConversationStatus === 'failed') {
			// 	getConversation();
			// 	setLoader(true);
			// }

			if (getConversationStatus === 'pending') {
				setLoader(true);
			}
		}
	}, [getConversationStatus]);

	React.useEffect(() => {
		if (showEditor === true) {
			setEditedConversation({});
		}
	}, [showEditor]);

	React.useEffect(() => {
		if (hideBox) {
			setHideBox(false);
			setShowReplyBox(false);
			setEditedConversation({});
			setClickedData({});
		}
	}, [hideBox]);

	const getUserPerformanceGoalsList = async () => {
		try {
			let requestParam: any = `moduleId=5&moduleDetailsId=${props.kr.performanceGoalLinkedFormCategoryId}
				&documentLinkId=${performanceGoalsList.performanceGoalCycleEmployeeId}&documentGoalLinkId=${performanceGoalsList.performanceGoalReviewCycleEmployeeId}`;
			const response: any = await dispatch(getPerformanceGoalDocument(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				dispatch(setPerfomanceGoalReviewAttachmentCount(response?.data.entityList.length));
			} else {
				dispatch(setPerfomanceGoalReviewAttachmentCount(0));
			}
		} catch (error) {}
	};
	const getSourceId = () => {
		let sourceId = 0;
		if (type === 1) {
			sourceId = okr.source ? okr.source : okr.goalObjectiveId;
		} else if (type === 2) {
			sourceId = kr.source ? kr.source : kr.goalKeyId;
		} else if (type === 3) {
			sourceId = kr.source ? kr.source : kr.goalKeyId;
		}
		return sourceId;
	};

	const getConversation = (page = 1) => {
		let data = '';
		if (isTeamReviewConversation) {
			data = `moduleId=${EnumCommentModuleId.PerformanceTeamReviewComments}&moduleDetailsId=${kr.performanceGoalReviewCycleEmployeeId}&pageSize=10&pageIndex=${page}&conversationLinkId=${kr.performanceGoalReviewCycleEmployeeId}&conversationGoalLinkId=${kr.performanceGoalReviewCycleEmployeeId}`;
		} else {
			data = `moduleId=${EnumCommentModuleId.PerformanceGoalReviewComments}&moduleDetailsId=${kr.performanceGoalLinkedFormCategoryId}&pageSize=10&pageIndex=${page}&conversationLinkId=${performanceGoalsList.performanceGoalReviewCycleEmployeeId}&conversationGoalLinkId=${performanceGoalsList.performanceGoalCycleEmployeeId}`;
		}
		dispatch(getGoalDataApi(data));
	};

	const canEditOrModify = () => {
		if (currentUser) {
			return false;
		}
		return true;
	};

	const handleClick = (event: any, data: any) => {
		if (isTaskEdited) {
			setModalProps({ open: true, event: event });
		} else {
			setModalProps({ open: false, event: {} });
			setAnchorEl(anchorEl ? null : event.currentTarget);
			setOpen(true);
			setClickedData(data);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
		setClickedData({});
	};

	const createConversation = (content = '', editedImageList = []) => {
		let payLoad: any = {
			commentDetailsId: 0,
			ConversationLinkId: isTeamReviewConversation
				? kr.performanceGoalReviewCycleEmployeeId
				: performanceGoalsList.performanceGoalReviewCycleEmployeeId,
			comments: conversationContent,
			moduleDetailsId: isTeamReviewConversation
				? kr.performanceGoalReviewCycleEmployeeId
				: kr.performanceGoalLinkedFormCategoryId,
			moduleId: isTeamReviewConversation
				? EnumCommentModuleId.PerformanceTeamReviewComments
				: EnumCommentModuleId.PerformanceGoalReviewComments,
			employeeId: props.drUserData.employeeId || loggedInUserDetail.employeeId,
			status: isTeamReviewConversation ? 2 : performanceGoalsList.status,
			performanceGoalCycleId: isTeamReviewConversation
				? kr.teamReviewUserListResponse.performanceGoalReviewCycleId
				: currentGoalReviewCycle && currentGoalReviewCycle?.performanceGoalReviewCycleId
				? currentGoalReviewCycle?.performanceGoalReviewCycleId
				: 0,
			documentRequest: [],
			type,
		};
		if (editedConversation.commentDetailsId) {
			payLoad.commentDetailsId = editedConversation.commentDetailsId;
			payLoad.comments = content;
			saveConversation(payLoad, editedImageList);
		} else {
			isTrialVersion() && GAevent('ConversationSubmit', 'Clicked tick in Conversations to submit', 'Clicked on tick');
			saveConversation(payLoad, imageList);
		}
	};
	const saveConversation = async (payload: any, images: any[]) => {
		if (
			payload.comments.trim() === '' ||
			payload.comments.trim() === '<p></p>' ||
			payload.comments.trim() === '<p><br></p>'
		) {
			return false;
		}
		const elem = document.createElement('span');
		elem.innerHTML = payload.comments;

		let imageData = elem.getElementsByTagName('img');
		images.forEach((value: any) => {
			if (payload.comments.includes(value.filePath)) {
				payload.documentRequest.push({
					storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					fileName: value.fileName,
					filePath: value.filePath,
				});
			}
		});
		let response: any = await dispatch(saveCommentGoalData(payload));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			// setIsCfrUpdated(true);
			if (!editedConversation.commentDetailsId) {
				setSavedId(response.data?.entity?.commentDetailsId);
				setTimeout(function () {
					setSavedId(0);
				}, 5000);
			}
			setEditedConversation({});
			//if (!editedConversation.commentDetailsId) {
			// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			//}
			getConversation();
			hideAddEditor();
			setDisableBtn(false);
		} else {
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const updateCount = async (itemId: any, add: boolean, updateColumn = '', commentCount = 0) => {
		let counter = add ? 1 : -1;
		let commentsListDataCopy = listData.map((listData: any) => listData);
		const index = commentsListDataCopy.findIndex((listData: any) => itemId === listData.commentDetailsId);
		if (index > -1) {
			if (updateColumn === 'like') {
				commentsListDataCopy[index].isLiked = add;
				commentsListDataCopy[index].totalLikes += counter;
			} else {
				if (commentsListDataCopy[index].totalReplies === 0 && counter > 0) {
					commentsListDataCopy[index].replyFirstName = userDetail.firstName;
					commentsListDataCopy[index].replyLastName = userDetail.lastName;
					commentsListDataCopy[index].replyFullName = userDetail.firstName + ' ' + userDetail.lastName;
					commentsListDataCopy[index].replyImagePath = userDetail.imagePath;
				} else if (commentsListDataCopy[index].totalReplies === 1 && counter < 0) {
					commentsListDataCopy[index].replyFirstName = null;
					commentsListDataCopy[index].replyLastName = null;
					commentsListDataCopy[index].replyFullName = null;
					commentsListDataCopy[index].replyImagePath = null;
				}
				commentsListDataCopy[index].totalReplies = commentCount;
			}
		}
		setListData(commentsListDataCopy);
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const selectedEl = document.getElementById(`${elementId}`);
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
			}
		}, 300);
	};
	const hideAddEditor = () => {
		setConversationContent('');
		setShowEditor(false);
	};
	const editConversation = (conversation: any) => {
		handleClose();
		hideAddEditor();
		setTimeout(function () {
			setEditedConversation(conversation);
		}, 200);
	};
	const handleDeleteConversation = (conversation: any) => {
		setEditedConversation(conversation);
		setOpenDeleteDialog(!openDeleteDialog);
		handleClose();
		setconversationAction('delete');
		hideAddEditor();
	};

	const likeConversationClicked = async (conversation: any, isLike: boolean) => {
		setLikeAnimation(conversation.commentDetailsId);
		let payload = {
			moduleDetailsId: conversation.commentDetailsId,
			moduleId: isTeamReviewConversation
				? EnumLikeModule.CommentsTeamReviewLike
				: EnumLikeModule.CommentsReviewGoalLike,
			employeeId: loggedInUserDetail.employeeId,
			isActive: isLike,
			status: isTeamReviewConversation ? 2 : performanceGoalsList.status,
			performanceGoalCycleId: isTeamReviewConversation
				? kr.teamReviewUserListResponse.performanceGoalReviewCycleId
				: performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId
				? performanceGoalCycle.performanceGoalCycleId
				: 0,
		};
		let response: any = await dispatch(saveLikeGoalData(payload));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			let likeCounter = isLike ? 1 : -1;
			var index = listData.findIndex((x: any) => x.commentDetailsId === conversation.commentDetailsId);
			let itemAttributes = listData[index];
			//itemAttributes.conversationReactions = [];
			if (payload.isActive) {
				itemAttributes = {
					...itemAttributes,
					totalLikes: itemAttributes.totalLikes + likeCounter,
					isLiked: isLike,
				};
				itemAttributes.conversationReactions.push({
					fullName: loggedInUserDetail.firstName + ' ' + loggedInUserDetail.lastName,
					employeeId: loggedInUserDetail.employeeId,
					imagePath: loggedInUserDetail.imagePath,
					emailId: loggedInUserDetail.emailId,
				});
			} else {
				itemAttributes = {
					...itemAttributes,
					totalLikes: itemAttributes.totalLikes - 1,
					isLiked: false,
				};
				var indexC = itemAttributes.conversationReactions.findIndex(
					(x: any) => x.employeeId === loggedInUserDetail.employeeId
				);
				if (indexC !== -1) {
					itemAttributes.conversationReactions.splice(indexC, 1);
				}
			}
			setListData([
				...listData.slice(0, index),
				Object.assign({}, listData[index], itemAttributes),
				...listData.slice(index + 1),
			]);
		} else {
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setOpenDeleteDialog(!openDeleteDialog);
		setEditedConversation({});
		setconversationAction('');
	};

	const handleConfirmClick = async () => {
		let data = `commentDetailsId=${editedConversation.commentDetailsId}`;
		const deleteTaskResponse: any = await dispatch(deleteCommentsPost(data));

		if (Boolean(deleteTaskResponse) && deleteTaskResponse.data.status === 200) {
			enqueueSnackbar(deleteTaskResponse?.data?.messageList?.messageSuccess, {
				variant: 'success',
				autoHideDuration: 5000,
			});
			getConversation();
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const setReplyListId = async (data: any, showReplyBox = false) => {
		if (conversationReplyId !== 0 && conversationReplyId === data.commentDetailsId) {
			setConversationReplyId(0);
		} else {
			setConversationReplyId(data.commentDetailsId);
		}
		setShowReplyBox(showReplyBox);
		setPrefilledUser(data);
	};

	const setPrefilledUser = (data: any) => {
		if (loggedInUserDetail.employeeId !== data.employeeId) {
			setReplyUser(data);
		} else {
			setReplyUser(null);
		}
	};

	const [likeAnchorEl, setLikeAnchorEl] = useState(null);
	const [openLike, setOpenLike] = useState<boolean>(false);
	const [likedId, setLikedId] = useState<number>(0);

	const handleLikePopperOpen = async (event: any, item: any) => {
		setLikeAnchorEl(event.currentTarget);
		setOpenLike(true);
		setLikedId(item.commentDetailsId);
	};
	const handleLikePopperClose = () => {
		setLikeAnchorEl(null);
		setOpenLike(false);
		setLikedId(0);
	};

	const handleCloseModal = (event: any, type: number) => {
		setModalProps({ open: false, event: {} });
		if (type === 1) {
			setShowReplyBox(false);
			setIsTaskEdited(false);
		}
	};
	return (
		<>
			{isTeamReviewConversation ? (
				<Box className='information-message'>
					<Typography variant='h6' className='no-sentence-case'>
						<ReportsTooltipIcon />
						<span dangerouslySetInnerHTML={{ __html: t('reviewConversationRedNote') }}></span>
					</Typography>
				</Box>
			) : conversationCount > 0 ? (
				<Box className='information-message'>
					<Typography variant='h6' className='no-sentence-case'>
						<ReportsTooltipIcon />
						<span dangerouslySetInnerHTML={{ __html: t('selfReviewConversationRedNote') }}></span>
					</Typography>
				</Box>
			) : (
				<></>
			)}
			<Box className='cfr-tab-inner-head'>
				<AddText
					{...props}
					tabSelected={tabSelected}
					showEditor={showEditor}
					setShowEditor={setShowEditor}
					setImageList={setImageList}
					imageList={imageList}
					showAvatar={true}
					avatarUserDetails={loggedInUserDetail}
					placeholderText={canEditOrModify() ? t('conversationGoal') : t('conversationGoal')}
					setEditorText={setConversationContent}
					editorText={conversationContent}
					handleSave={createConversation}
					handleCancel={() => {
						setConversationContent('');
						setEditedConversation({});
					}}
					errorMessage={t('conversationsRequired')}
					showHelpText={false}
					disableBtn={disableBtn}
					setDisableBtn={setDisableBtn}
					uploadCase={'CONVERSATION'}
					isAdded={true}
					isTaskEdited={isTaskEdited}
					setIsTaskEdited={setIsTaskEdited}
					setUnsavedCfrProps={setUnsavedCfrProps}
				/>
				{/* // ) : (
				// 	<Box className='task-help-text'>
				// 		<Typography className='helper-text'>{t('conversationsNotPermitted')}</Typography>
				// 	</Box>
				// )} */}
				<Box className='count-head'>
					<Typography variant='body2'>
						{conversationCount} {t('commentsLabel')}
					</Typography>
				</Box>
			</Box>
			<Box className={`tab-panel-inner ${listData && listData.length > 0 ? ' ' : 'no-record-available'}`}>
				{listPending ? (
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				) : (
					<>
						{listData && listData.length > 0 ? (
							<>
								<List className='cfr-list comment-list' disablePadding>
									{listData.map((item: any, index: any) => (
										<ListItem
											id={`${'recogConversationPost-' + item.commentDetailsId}`}
											className={`${highlightIds.indexOf(item.commentDetailsId) !== -1 ? 'highlight' : ''}`}
										>
											<Box className='conversation-list-content'>
												{item.imagePath ? (
													<Avatar className={'user-avtar'} src={item.imagePath} />
												) : (
													<Avatar
														className={'user-avtar'}
														style={{
															backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
															color: getRandomColor(item.colorCode),
														}}
													>
														{getUserName(item)}
													</Avatar>
												)}
												<Box className={`comment-section`}>
													<Box className='cfr-list-inner'>
														<Box className='cfr-list-content'>
															<Typography className='author-name'>{item.fullName}</Typography>
															<Box className='timestamp'>
																<Typography variant='body2'>{getTime(item.createdOn)}</Typography>{' '}
																{item.isEdited ? <Typography variant='body2'>{t('editedLabel')}</Typography> : <></>}
															</Box>
															<Box className='collapse-content'>
																{editedConversation.commentDetailsId &&
																item.commentDetailsId === editedConversation.commentDetailsId &&
																conversationAction !== 'delete' ? (
																	<EditConversations
																		{...props}
																		canEditOrModify={canEditOrModify}
																		createConversation={createConversation}
																		description={item.comments}
																		editedConversation={editedConversation}
																		setEditedConversation={setEditedConversation}
																		isTaskEdited={isTaskEdited}
																		setIsTaskEdited={setIsTaskEdited}
																		setUnsavedCfrProps={setUnsavedCfrProps}
																	/>
																) : (
																	<ReadMore {...props} text={item.comments} isHtmlContent={true} />
																)}
															</Box>
															{editedConversation.commentDetailsId &&
															item.commentDetailsId === editedConversation.commentDetailsId &&
															conversationAction !== 'delete' ? (
																<></>
															) : item.type === 2 ? (
																<Chip className={'progress-notes'} label={t('progressNoteLabel')} />
															) : (
																<></>
															)}
														</Box>
														{loggedInUserDetail.employeeId === item.employeeId &&
															(item.moduleId === EnumCommentModuleId.PerformanceGoalReviewComments ||
																item.moduleId === EnumCommentModuleId.PerformanceTeamReviewComments) && (
																<OKRButton
																	title={t('moreOptions')}
																	className={`menu-btn ${
																		open && clickedData.commentDetailsId === item.commentDetailsId ? 'active' : ''
																	}`}
																	handleClick={(e: any) => {
																		handleClick(e, item);
																	}}
																	icon={<KebabIcon />}
																/>
															)}
													</Box>
													<Box className='cfr-reply-action'>
														<Box className='cfr-reply-btm-left'>
															{/* <OKRButton
																className={`like-border-btn ${item.isLiked ? 'active' : ''}`}
																text={item.totalLikes ? item.totalLikes : ' 0'}
																handleClick={(e: any) => {
																	!isPastCycle && likeConversationClicked(item);
																}}
																icon={item.isLiked ? <LikeFilledIcon /> : <LikeIcon />}
																title={
																	item.conversationReactions.length > 0 ? (
																		<List disablePadding>
																			{item.conversationReactions.map((element: any) => {
																				return <ListItem>{element.fullName}</ListItem>;
																			})}
																		</List>
																	) : undefined
																}
																tooltipClassName={'tooltip-html'}
																disableRipple={isPastCycle}
															/> */}
															{!item.isLiked ? (
																<OKRButton
																	className='like-border-btn'
																	icon={<LikeIcon />}
																	//text={item.totalLikes ? item.totalLikes : ' 0'}
																	handleClick={(e: any) => {
																		likeConversationClicked(item, true);
																	}}
																	tooltipClassName={'tooltip-html'}
																/>
															) : (
																<Box className='like-filled-btn'>
																	<OKRButton
																		className={`like-border-btn ${
																			likeAnimation === item.commentDetailsId ? 'btn-anim' : ''
																		}`}
																		icon={
																			<>
																				{likeAnimation === item.commentDetailsId && (
																					<AnimatedIcon
																						className='like-animation-img'
																						width='60'
																						type='image/svg+xml'
																						alt='No user'
																						imagePath={LikeImg}
																					/>
																				)}
																				<LikeFilledIcon />
																			</>
																		}
																		//text={<span className='remove-img'></span>}
																		handleClick={(e: any) => {
																			likeConversationClicked(item, false);
																		}}
																		tooltipClassName={'tooltip-html'}
																		disabled={
																			item.moduleId !== EnumCommentModuleId.PerformanceGoalReviewComments &&
																			item.moduleId !== EnumCommentModuleId.PerformanceTeamReviewComments
																		}
																	/>
																</Box>
															)}
															<OKRButton
																className='comment-border-btn'
																icon={<CommentIcon />}
																text={t('reply')}
																handleClick={() => {
																	if (
																		item.moduleId === EnumCommentModuleId.PerformanceGoalReviewComments ||
																		item.moduleId === EnumCommentModuleId.PerformanceTeamReviewComments
																	) {
																		setShowReplyBox(true);
																	}
																	setConversationReplyId(item.commentDetailsId);
																	setPrefilledUser(item);

																	setTimeout(() => {
																		const selectedEl = document.getElementById(`create-reply-box`);
																		if (selectedEl) {
																			selectedEl.scrollIntoView({
																				behavior: 'smooth',
																				block: 'nearest',
																				inline: 'start',
																			});
																		}
																	}, 500);
																}}
																//disabled={item.moduleId !== 7}
															/>
														</Box>
														<Box className='cfr-reply-btm-right'>
															<List disablePadding>
																{item.totalLikes > 0 && (
																	<ListItem
																		aria-owns={open ? 'mouse-over-popover' : undefined}
																		aria-haspopup='true'
																		onMouseEnter={(e: any) => {
																			item.totalLikes > 0 && handleLikePopperOpen(e, item);
																		}}
																		onMouseLeave={handleLikePopperClose}
																		className={item.totalLikes > 0 ? 'active' : ''}
																	>
																		<ListItemText
																			className={item.totalLikes > 0 ? 'like-popup' : ''}
																			primary={`${item.totalLikes} ${
																				item.totalLikes > 1 ? t('likedLevel') : t('likeLevel')
																			}`}
																		/>

																		{openLike && item.commentDetailsId === likedId && (
																			<Box>
																				<PopperMenu
																					{...props}
																					children={
																						<>
																							{item?.conversationReactions?.length > 0 &&
																								item.conversationReactions.map((likeList: any) => {
																									return (
																										<ListItem>
																											<Box className='user-info'>
																												<Box className='user-img'>
																													{likeList?.imagePath ? (
																														<Avatar src={likeList?.imagePath} />
																													) : (
																														<Avatar>
																															{getUserName({
																																fullName: '',
																																firstName: likeList?.firstName,
																																lastName: likeList?.lastName,
																															})}
																														</Avatar>
																													)}
																												</Box>
																												<Box className='user-info-details'>
																													{likeList?.fullName?.length > 30 ? (
																														<Tooltip title={likeList.fullName} arrow>
																															<Typography variant='subtitle2'>
																																{likeList?.fullName.substring(0, 30)}
																																...
																															</Typography>
																														</Tooltip>
																													) : (
																														<Typography>{likeList.fullName}</Typography>
																													)}

																													{likeList?.emailId?.length > 28 ? (
																														<Tooltip title={likeList.emailId} arrow>
																															<Typography variant='subtitle2'>
																																{likeList?.emailId.substring(0, 28)}
																																...
																															</Typography>
																														</Tooltip>
																													) : (
																														<Typography variant='subtitle2'>
																															{likeList?.emailId}
																														</Typography>
																													)}
																												</Box>
																											</Box>
																										</ListItem>
																									);
																								})}
																						</>
																					}
																					popperClassName={'conversation-drawer-like recog-liked-by-dropdown'}
																					anchorEl={likeAnchorEl}
																					handleClose={handleLikePopperClose}
																					popperPlacement={'bottom'}
																					open={openLike}
																				/>
																			</Box>
																		)}
																	</ListItem>
																)}
																{item.totalReplies > 0 && (
																	<ListItem className={item.totalReplies > 0 ? 'active' : ''}>
																		<ListItemText
																			primary={`${item.totalReplies} ${
																				item.totalReplies > 1 ? t('Replies') : t('reply')
																			}`}
																			onClick={() => {
																				setReplyListId(item, false);
																			}}
																		/>
																	</ListItem>
																)}
															</List>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box className='cfr-replies-trail'>
												<Box className='no-of-replies'>
													{item.totalReplies === 1 ? (
														<>
															<OKRButton
																className='no-of-replies-btn'
																icon={<ReplyArrow />}
																text={
																	<>
																		{item?.replyImagePath ? (
																			<Avatar src={item?.replyImagePath} />
																		) : (
																			<Avatar>
																				{getUserName({
																					fullName: '',
																					firstName: item?.replyFirstName,
																					lastName: item?.replyLastName,
																				})}
																			</Avatar>
																		)}
																		{item.replyFullName} {t('Replied')}
																	</>
																}
																handleClick={() => {
																	setReplyListId(item);
																}}
															/>
														</>
													) : item.totalReplies > 1 ? (
														<>
															<OKRButton
																className='no-of-replies-btn'
																icon={<ReplyArrow />}
																text={`${item.totalReplies} ${t('Replies')}`}
																handleClick={() => {
																	setReplyListId(item);
																}}
															/>
														</>
													) : (
														<></>
													)}
												</Box>
											</Box>
											{conversationReplyId === item.commentDetailsId ? (
												<ReplyList
													{...props}
													commentData={item}
													setReplyCommentId={setConversationReplyId}
													commentDetailsId={item.commentDetailsId}
													commentsData={item.employeeId !== loggedInUserDetail.employeeId ? item : null}
													updateCount={updateCount}
													showReplyBox={showReplyBox}
													setShowReplyBox={setShowReplyBox}
													replyHighlight={replyHighlight}
													setReplyHighlight={setReplyHighlight}
													getSourceId={getSourceId}
													setUnsavedCfrProps={setUnsavedCfrProps}
													setIsTaskEdited={setIsTaskEdited}
													isTaskEdited={isTaskEdited}
													scrollTo={scrollTo}
													replyUser={replyUser}
													setReplyUser={setReplyUser}
													setPrefilledUser={setPrefilledUser}
													setModalProps={setModalProps}
													setHideBox={setHideBox}
													hideBox={hideBox}
													performanceGoalsList={performanceGoalsList}
													isViewOnly={
														item.moduleId !== EnumCommentModuleId.PerformanceGoalReviewComments &&
														item.moduleId !== EnumCommentModuleId.PerformanceTeamReviewComments
													}
													isTeamReviewConversation={isTeamReviewConversation}
													kr={kr}
												/>
											) : (
												<></>
											)}
										</ListItem>
									))}
								</List>
								{showLoadMore && (
									<List className='load-more-ul' disablePadding>
										<ListItem className='load-more'>
											<OKRButton
												handleClick={(e) => {
													getConversation(pageIndex + 1 || 1);
												}}
												className={`load-more-btn`}
												text={t('loadMore')}
											/>
										</ListItem>
									</List>
								)}
							</>
						) : (
							<CfrNoRecord
								{...props}
								noRecordHead={isTeamReviewConversation ? t('noConversationHeadForReview') : t('noConversationHead')}
								noRecordMsg={
									isTeamReviewConversation ? t('noConversationHeadForReviewMsg') : t('noConversationGoalSubtitle')
								}
								noRecordImg={ConversationImg}
							/>
						)}
					</>
				)}
				<PopperMenu
					anchorEl={anchorEl}
					open={open}
					handleClose={handleClose}
					popperClassName={'popper-menu popper-menu-type2 popper-cfr-menu'}
					{...props}
					children={
						<>
							<ListItem
								onClick={() => {
									editConversation(clickedData);
								}}
							>
								<ListItemAvatar>
									<EditIcon />
								</ListItemAvatar>

								<ListItemText primary={t('editLabel')} />
							</ListItem>
							<ListItem
								onClick={() => {
									handleDeleteConversation(clickedData);
								}}
							>
								<ListItemAvatar>
									<DeleteIcon />
								</ListItemAvatar>
								<ListItemText primary={t('delete')} />
							</ListItem>
						</>
					}
				></PopperMenu>
			</Box>

			{modalProps.open && (
				<AlertDialog message={t('unSavedItemAlert')} handleCloseModal={handleCloseModal} modalOpen={modalProps.open} />
			)}
			{openDeleteDialog && (
				<AlertDialog
					module='information'
					message={t('deleteConversationPrompt')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
		</>
	);
};
