import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import LinearLoader from '../Common/Loader';
import { PerformanceDevTabPanel, PerformanceDevTabs } from './PerformanceDevTabs';
import Header from '../../containers/Header';
import { currentDashboardTab } from '../../action/common';
import { useDispatch, useSelector } from 'react-redux';
import { PerformanceImprovementPlan } from './PerformanceImprovementPlan';
import { PerformanceFeedback } from './PerformanceFeedback';
import { Enums } from '../../config/enums';
import '../../styles/pages/performance-development/pipCreateForm.scss';
import '../../styles/pages/performance-development/pipList.scss';
import '../../styles/pages/performance-development/pip-view-mode.scss';
import '../../styles/pages/performance-development/performanceFeedback.scss';
import '../../styles/pages/performance-development/upload-documents.scss';
import { ThreeSixetyExperience } from './ThreeSixetyExperience';
import { checkSupportPermission } from '../../config/utils';
import { supportModuleAlignGoals } from '../Admin/AdminConstant';

export const PerformanceDevelopment = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);

	const [tabSelected, setTabSelected] = useState(Enums.ZERO);
	const [loader, setLoader] = useState<boolean>(false);
	const [feedbackDetailsForPip, setFeedbackDetailsForPip] = useState<any>(null);
	const [goBackToFeedback, setGoBackToFeedback] = useState<any>(null);
	const [openViewFeedbackForm, setOpenViewFeedbackForm] = useState<any>({
		open: false,
		feedbackItem: null,
	});
	const [openCreateFeedbackForm, setOpenCreateFeedbackForm] = useState<any>({
		open: false,
		feedbackFormEditDetails: null,
		type: 'Add',
	});

	useEffect(() => {
		if (dashboardCurrentTab !== tabSelected) {
			setTabSelected(dashboardCurrentTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardCurrentTab]);

	const handleTabChange = (event: any, newValue: any) => {
		setTabSelected(newValue);
		dispatch(currentDashboardTab(newValue));
		setOpenViewFeedbackForm({ open: false, feedbackItem: null });
	};
	const handleCreatePIPFormOpenClick = (e: any, feedbackDetails: any, feedbackItem: any) => {
		try {
			// console.log('handleCreatePIPFormOpenClick ===> ', feedbackDetails, feedbackItem);
			setGoBackToFeedback(feedbackItem);
			setFeedbackDetailsForPip(feedbackDetails);
			setOpenViewFeedbackForm({
				open: false,
				feedbackItem: null,
			});
			setOpenCreateFeedbackForm({
				open: false,
				feedbackFormEditDetails: null,
				type: 'Add',
			});
			setTabSelected(Enums.TWO);
			dispatch(currentDashboardTab(Enums.TWO));
		} catch (error) {
			console.error(error);
		}
	};
	const goBackToFeedbackDetails = () => {
		const goBackToFeedbackDetails = goBackToFeedback ? { ...goBackToFeedback } : null;
		setGoBackToFeedback(null);
		handleTabChangeDirect(goBackToFeedbackDetails);
	};
	const handleTabChangeDirect = (goBackToFeedbackDetails: any) => {
		setTabSelected(1);
		setTimeout(() => {
			handleFeedbackItemClick({}, goBackToFeedbackDetails);
		}, 1000);
		dispatch(currentDashboardTab(1));
	};
	const handleFeedbackItemClick = (event: any, feedbackItem: any) => {
		try {
			setOpenViewFeedbackForm({
				open: true,
				feedbackItem,
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='page-subheader' id='page-perform-dev-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('prsDevelopment')}</Typography>
						</Box>
						<PerformanceDevTabs tabSelected={tabSelected} handleTabChange={handleTabChange} {...props} />
					</Box>
					<Box className='page-subheader-right'>
						<Header {...props} fromTab={true} isPerformDevelopment={true} />
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				{checkSupportPermission(supportModuleAlignGoals) ? (
					<PerformanceDevTabPanel value={tabSelected} index={0}>
						<ThreeSixetyExperience
							loader={loader}
							setLoader={setLoader}
							handleCreatePIPFormOpenClick={handleCreatePIPFormOpenClick}
							openViewFeedbackForm={openViewFeedbackForm}
							setOpenViewFeedbackForm={setOpenViewFeedbackForm}
							handleFeedbackItemClick={handleFeedbackItemClick}
							openCreateFeedbackForm={openCreateFeedbackForm}
							setOpenCreateFeedbackForm={setOpenCreateFeedbackForm}
							setFeedbackDetailsForPip={setFeedbackDetailsForPip}
							setGoBackToFeedback={setGoBackToFeedback}
						/>
					</PerformanceDevTabPanel>
				) : (
					<></>
				)}
				<PerformanceDevTabPanel value={tabSelected} index={1}>
					<PerformanceFeedback
						loader={loader}
						setLoader={setLoader}
						handleCreatePIPFormOpenClick={handleCreatePIPFormOpenClick}
						openViewFeedbackForm={openViewFeedbackForm}
						setOpenViewFeedbackForm={setOpenViewFeedbackForm}
						handleFeedbackItemClick={handleFeedbackItemClick}
						openCreateFeedbackForm={openCreateFeedbackForm}
						setOpenCreateFeedbackForm={setOpenCreateFeedbackForm}
						setFeedbackDetailsForPip={setFeedbackDetailsForPip}
						setGoBackToFeedback={setGoBackToFeedback}
					/>
				</PerformanceDevTabPanel>
				<PerformanceDevTabPanel value={tabSelected} index={2}>
					<PerformanceImprovementPlan
						loader={loader}
						setLoader={setLoader}
						feedbackDetailsForPip={feedbackDetailsForPip}
						setFeedbackDetailsForPip={setFeedbackDetailsForPip}
						goBackToFeedback={goBackToFeedback}
						setGoBackToFeedback={setGoBackToFeedback}
						goBackToFeedbackDetails={goBackToFeedbackDetails}
					/>
				</PerformanceDevTabPanel>
			</Box>
		</>
	);
};
