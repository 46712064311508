import React, { useEffect, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Link,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { useTranslation } from 'react-i18next';
import { DownFilledArrow, RightArrowIcon } from '../../config/svg/ArrowSvg';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { CycleIcon } from '../../config/svg/CommonSvg';

export const CycleDropdownDownloadReport: React.FC<any> = (props) => {
	const {
		handleCycleClick,
		futureCyclesList,
		pastCyclesList,
		currentCycleSelectionData,
		className,
		isCycleDisable,
		dropdownType,
		performanceCycleReportList,
		setPerformList,
		performList,
	} = props;
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState<Boolean>(false);
	const [open, setOpen] = React.useState<Boolean>(false);
	const [pastCycles, setPastCycles] = useState(false);
	const [futureCycles, setFutureCycles] = useState(false);
	const [cycleList, setCycleList] = useState<any[]>([]);
	// const [performList, setPerformList] = useState<any>(null);

	// useEffect(() => {
	// 	if (performanceCycleReportList && performanceCycleReportList.length > 0) {
	// 		setPerformList(performanceCycleReportList[0]);
	// 	}
	// }, [performanceCycleReportList]);
	useEffect(() => {
		if (currentCycleSelectionData?.type === 3) {
			setPastCycles(true);
			setCycleList(pastCyclesList);
		} else if (currentCycleSelectionData?.type === 4) {
			setFutureCycles(true);
			setCycleList(futureCyclesList);
		} else {
			setPastCycles(false);
			setFutureCycles(false);
		}
	}, [currentCycleSelectionData, open]);

	const handleChange = (panel: any) => (event: any, isExpanded: any) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
		setPastCycles(false);
		setFutureCycles(false);
	};
	const handlePastCycles = () => {
		setCycleList(pastCyclesList);
		setPastCycles(!pastCycles);
	};
	const handleFutureCycles = () => {
		setFutureCycles(!futureCycles);
		setCycleList(futureCyclesList);
	};
	return (
		<Box className={`cycle-dropdown ${className || ''}`}>
			{dropdownType !== 'performReport' ? (
				<>
					<OKRButton
						text={`${
							currentCycleSelectionData?.cycleLabel ? currentCycleSelectionData?.cycleLabel : t('currentCycle')
						} `}
						className={`cycle-dropdown-btn  ${open ? 'open' : ''}`}
						id='cycle-dropdown-btn'
						icon={<DownFilledArrow />}
						handleClick={handleClick}
						disabled={isCycleDisable}
					/>
					{open && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<Box className='tenure-list' id='cycle-dropdown-wrapper'>
								{!pastCycles && (
									<Box className='cycle-quick-links'>
										<List>
											<ListItem
												id='current-cycle-link'
												className={`${currentCycleSelectionData?.type === 1 ? 'active' : ''}`}
												onClick={() => {
													handleClickAway();
													handleCycleClick(2, 0, t('currentCycle'));
												}}
											>
												<ListItemText primary={t('currentCycle')} />
											</ListItem>
											<ListItem id='past-cycle-link' onClick={handlePastCycles}>
												<ListItemText primary={t('pastCycle')} />
											</ListItem>
										</List>
									</Box>
								)}
								{pastCycles && (
									<Box className='cycle-sub-panel'>
										<Box className='back-to-main'>
											<Typography variant='body2'>
												<Link id='back-btn' onClick={pastCycles ? handlePastCycles : handleFutureCycles}>
													<BackArrow /> {t('back')}
												</Link>
												{pastCycles ? t('yourPastOKRsCycles') : t('yourFutureOKRsCycles')}
											</Typography>
										</Box>

										{cycleList.map((cycleYears: any, idx: any) => (
											<ExpansionPanel
												key={`cycle-panel-`}
												expanded={
													currentCycleSelectionData?.cycleId.length > 0 &&
													cycleYears.quarterDetails.find(
														(item: any) => item.organisationCycleId === currentCycleSelectionData?.cycleId
													)
														? true
														: expanded === cycleYears
												}
												onChange={handleChange(cycleYears)}
												id={`cycle-panel-`}
											>
												<ExpansionPanelSummary
													expandIcon={<RightArrowIcon />}
													aria-controls='panel1a-content'
													id='cycle-panel-header'
												>
													<Typography>
														Year {cycleYears.year} {cycleYears.isOkrAvailable && <CycleIcon />}
													</Typography>
												</ExpansionPanelSummary>
												<ExpansionPanelDetails>
													<List component='nav' aria-label={t('HeaderTenureNavigation')}>
														{cycleYears.quarterDetails.map((QuaterDetails: any) => (
															<ListItem
																button
																key={`cycle-list-item`}
																className={`${
																	currentCycleSelectionData?.cycleId.length > 0 &&
																	currentCycleSelectionData?.cycleId[0] === QuaterDetails.organisationCycleId
																		? 'active'
																		: ''
																}`}
																id={`cycle-list-item`}
																onClick={() => {
																	handleClickAway();
																	handleCycleClick(
																		pastCycles ? 3 : 4,
																		QuaterDetails.organisationCycleId,
																		`${QuaterDetails.symbol}, ${cycleYears.year}`
																	);
																}}
															>
																<ListItemText
																	className={`${
																		currentCycleSelectionData?.cycleId.length > 0 &&
																		currentCycleSelectionData?.cycleId[0] === QuaterDetails.organisationCycleId
																			? 'active'
																			: ''
																	}`}
																	primary={`${QuaterDetails.symbol}, ${cycleYears.year}`}
																	secondary={QuaterDetails.isOkrAvailable && <CycleIcon />}
																/>
															</ListItem>
														))}
													</List>
												</ExpansionPanelDetails>
											</ExpansionPanel>
										))}
									</Box>
								)}
							</Box>
						</ClickAwayListener>
					)}
				</>
			) : (
				<>
					{performanceCycleReportList && performanceCycleReportList.length > 1 ? (
						<OKRButton
							className={`cycle-dropdown-btn  ${open ? 'open' : ''}`}
							id='cycle-dropdown-btn'
							icon={<DownFilledArrow />}
							handleClick={handleClick}
							text={
								performList && performList?.name && performList?.name.length > 35
									? performList?.name.substring(0, 35) + '...'
									: performList?.name
							}
							title={performList?.name && performList?.name.length > 35 ? performList?.name : ''}
						/>
					) : (
						<>
							{performList?.name && performList?.name.length > 35 ? (
								<Tooltip arrow title={performList?.name} aria-label='Performance Name'>
									<Typography variant='h5' className='font-weight-normal'>
										{performList?.name.substring(0, 35)}...
									</Typography>
								</Tooltip>
							) : (
								<Typography variant='h5' className='font-weight-normal'>
									{performList?.name}
								</Typography>
							)}
						</>
					)}
					{open && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<Box className='tenure-list' id='cycle-dropdown-wrapper'>
								<Box className='cycle-quick-links'>
									<List>
										{performanceCycleReportList &&
											performanceCycleReportList.length &&
											performanceCycleReportList.map((item: any, index: any) => (
												<ListItem
													key={item.performanceCycleId}
													className={performList?.name === item?.name ? 'active' : ''}
													onClick={(e: any) => {
														handleClickAway();
														setPerformList(item);
														handleCycleClick(item.performanceCycleId, item.name, '', item.performanceGoalReviewCycleId); // in here
													}}
												>
													<ListItemText primary={item.name} />
												</ListItem>
											))}
									</List>
								</Box>
							</Box>
						</ClickAwayListener>
					)}
				</>
			)}
		</Box>
	);
};
